import Vue from 'vue';
import dayjs from '@/utility/dayjs';
import {
  removeGroupGames,
  toggleGamesGroupMembership,
  toggleGamesIsLoading,
  updateGetGroupGamesParams,
  parseGroupGamesData,
  getGroupErrorTranslations,
} from '@/utility/groups';
import api from '@/api';
import mutationTypes from '../mutationTypes';
import { handleError, handleSuccess } from '../actions';

export default {
  state: {
    groups: [],
    group: {},
    createdGroup: {},
    createdTempGroupId: '',
    addedGroupGamesData: {},
    allGroupTempGamesData: {},
    addedTempGroupGamesData: {},
    allTempGroupGamesData: {},
    totalGamesInGroup: 0,
    totalGamesInTempGroup: 0,
    groupAutosaving: false,
    groupChangesSaved: false,

    filterConfig: {
      search: true,
      company: true,
    },
  },

  getters: {
    groupsFilterConfig: (state) => state.filterConfig,
    group: (state) => state.group,
    createdGroup: (state) => state.createdGroup,
    createdTempGroupId: (state) => state.createdTempGroupId,
    addedGroupGamesData: (state) => state.addedGroupGamesData,
    allGroupTempGamesData: (state) => state.allGroupTempGamesData,
    addedTempGroupGamesData: (state) => state.addedTempGroupGamesData,
    allTempGroupGamesData: (state) => state.allTempGroupGamesData,
    totalGamesInGroup: (state) => state.totalGamesInGroup,
    totalGamesInTempGroup: (state) => state.totalGamesInTempGroup,
    groupAutosaving: (state) => state.groupAutosaving,
    groupChangesSaved: (state) => state.groupChangesSaved,

    groups: (state) => state.groups
      .map((group) => ({
        ...group,
        updatedAt: group.updatedAt ? dayjs(group.updatedAt).format('lll') : 'N/A',
        createdAt: group.createdAt ? dayjs(group.createdAt).format('lll') : 'N/A',
      }))
      .sort((a, b) => b.priority - a.priority),
  },

  actions: {
    getGroups: ({ commit }, params) => api
      .getGroups(params)
      .then((groups) => commit(mutationTypes.SET_GROUPS, groups))
      .catch((err) => handleError({ commit }, err)),

    editGroupPosition: ({ commit, rootState, dispatch }, params) => api
      .editGroup(params)
      .then(() => handleSuccess({ commit }, 'Successfully updated group order!'))
      .catch((err) => handleError({ commit }, err))
      .finally(() => dispatch('getGroups', rootState.filterParams)),

    createTempGamesListId: ({ commit }, params) => api
      .createTempGamesListId(params)
      .catch((err) => handleError({ commit }, err)),

    createTempGroupFirstStep: ({ commit }, params) => api
      .createTempGroup(params)
      .then((data) => commit(mutationTypes.SET_CREATED_TEMP_GROUP_ID, data.tempGroupId))
      .catch((err) => {
        handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'group' }));

        return {
          isError: true,
          code: err.response?.data?.code,
        };
      }),

    createTempGroupFinalStep: ({ commit }, params) => api
      .createGroup(params)
      .then(() => handleSuccess({ commit }, Vue.filter('translate')('group_created_notification_info', { groupTitle: params.groupTitle })))
      .catch((err) => {
        handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'group' }));

        return {
          isError: true,
          code: err.response?.data?.code,
        };
      }),

    createGroup: ({ commit }, params) => api
      .createGroup(params)
      .then((data) => commit(mutationTypes.SET_CREATED_GROUP, {
        id: data.groupId,
        title: params.group.title,
      }))
      .catch((err) => {
        handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'group' }));

        return {
          isError: true,
          code: err.response?.data?.code,
        };
      }),

    getGroup: ({ commit }, params) => {
      commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);

      return api
        .getGroups(params)
        .then((data) => {
          commit(mutationTypes.SET_GROUP, data[0]);
          commit(mutationTypes.SET_TOTAL_GROUP_GAMES, data[0]?.gamesCount);
        })
        .catch((err) => { handleError({ commit }, err); });
    },

    editGroup: ({ commit }, params) => {
      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);

      return api
        .editGroup(params)
        .then(() => {
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true);
        })
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'group' }));

          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
        });
    },

    editTempGroup: ({ commit }, params) => api
      .editTempGroup(params)
      .catch((err) => {
        handleError({ commit }, getGroupErrorTranslations(err, { entityType: 'group' }));

        return {
          isError: true,
          code: err.response?.data?.code,
        };
      }),

    saveGroupTempGames: ({ commit }, params) => {
      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);

      return api
        .saveGroupTempGames(params)
        .then((data) => {
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true);
          commit(mutationTypes.SET_TOTAL_GROUP_GAMES, data.totalGamesInGroup);
        })
        .catch((err) => {
          handleError({ commit }, getGroupErrorTranslations(err));
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);

          return {
            isError: true,
            code: err.response?.data?.code,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
        });
    },

    editGroupDone: ({ commit }, groupTitle) => {
      handleSuccess({ commit }, Vue.filter('translate')('group_updated_notification_info', { groupTitle }));
    },

    setCreatedGroup: ({ commit }, group) => {
      commit(mutationTypes.SET_CREATED_GROUP, group);
    },

    setGroups: ({ commit }, groups) => {
      commit(mutationTypes.SET_GROUPS, groups);
    },

    toggleGroupActivity: ({ commit }, params) => api
      .editGroup(params)
      .then(() => {
        commit(mutationTypes.UPDATE_GROUP, params.fullGroupData);
        handleSuccess({ commit }, Vue.filter('translate')('group_activity_notification_info'));
      })
      .catch((err) => handleError({ commit }, err)),

    deleteGroup: ({ commit }, params) => api
      .deleteGroup(params)
      .then(() => handleSuccess({ commit }, Vue.filter('translate')('group_deleted_notification_info', { groupTitle: params.groupTitle })))
      .catch((err) => {
        handleError({ commit }, err);
        return {
          isError: true,
        };
      }),

    getAllGroupGames: ({ commit }, params) => api
      .getGroupGames(params)
      .then((data) => commit(mutationTypes.SET_ALL_GROUP_GAMES_DATA, data))
      .catch((err) => {
        handleError({ commit }, err);
        return {
          isError: true,
        };
      }),

    getAddedGroupGames: ({ state, commit }, params) => {
      const page = state.addedGroupGamesData.pagination?.page;
      const apiParams = updateGetGroupGamesParams(params, page);

      return api
        .getGroupGames(apiParams)
        .then((data) => {
          commit(mutationTypes.SET_ADDED_GROUP_GAMES_DATA, {
            data,
            isNextPage: params.isNextPage,
            shouldRefetchAllPages: params.shouldRefetchAllPages,
          });
        })
        .catch((err) => {
          handleError({ commit }, err);
          return {
            isError: true,
          };
        });
    },

    getAllTempGroupGames: ({ commit }, params) => api
      .getGroupGames(params)
      .then((data) => commit(mutationTypes.SET_ALL_TEMP_GROUP_GAMES_DATA, data))
      .catch((err) => {
        handleError({ commit }, err);
        return {
          isError: true,
        };
      }),

    getAddedTempGroupGames: ({ state, commit }, params) => {
      const page = state.addedTempGroupGamesData.pagination?.page;
      const apiParams = updateGetGroupGamesParams(params, page);

      return api
        .getTempGroupGames(apiParams)
        .then((data) => {
          commit(mutationTypes.SET_ADDED_TEMP_GROUP_GAMES_DATA, {
            data,
            isNextPage: params.isNextPage,
            shouldRefetchAllPages: params.shouldRefetchAllPages,
          });
          if (params.isInitialFetch) {
            commit(mutationTypes.SET_TOTAL_TEMP_GROUP_GAMES, data.pagination.total);
          }
        })
        .catch((err) => {
          handleError({ commit }, err);
          return {
            isError: true,
          };
        });
    },

    updateAllTempGroupGames: ({ commit }, params) => {
      const { gameIds, filters, selectAll } = params.payload;
      const mutateGamesParams = {
        areAllGamesSelected: selectAll,
        selectedGameIds: gameIds,
        excludedGameIds: filters.excluded,
      };

      commit(mutationTypes.TOGGLE_ALL_TEMP_GROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });
      commit(mutationTypes.TOGGLE_ADDED_TEMP_GROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });

      return api
        .updateGroupTempGames(params)
        .then((data) => {
          commit(mutationTypes.TOGGLE_TEMP_GROUP_MEMBERSHIP_GAMES, {
            ...mutateGamesParams,
            action: params.action,
          });
          commit(mutationTypes.REMOVE_ADDED_TEMP_GROUP_GAMES, mutateGamesParams);
          commit(mutationTypes.SET_TOTAL_TEMP_GROUP_GAMES, data.totalGamesInGroup);
        })
        .catch((err) => {
          handleError({ commit }, err);
          return {
            isError: true,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_ALL_TEMP_GROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
          commit(mutationTypes.TOGGLE_ADDED_TEMP_GROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
        });
    },

    updateGroupTempGames: ({ commit }, params) => {
      const { gameIds, filters, selectAll } = params.payload;
      const mutateGamesParams = {
        areAllGamesSelected: selectAll,
        selectedGameIds: gameIds,
        excludedGameIds: filters.excluded,
      };

      commit(mutationTypes.TOGGLE_ALL_GROUP_TEMP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });

      return api
        .updateGroupTempGames(params)
        .then(() => commit(mutationTypes.TOGGLE_ALL_GROUP_MEMBERSHIP_TEMP_GAMES, {
          ...mutateGamesParams,
          action: params.action,
        }))
        .catch((err) => {
          handleError({ commit }, err);
          return {
            isError: true,
          };
        })
        .finally(() => commit(mutationTypes.TOGGLE_ALL_GROUP_TEMP_GAMES_LOADING, {
          ...mutateGamesParams,
          isLoading: false,
        }));
    },

    removeGamesFromGroup: ({ commit }, params) => {
      const { gameIds, filters, selectAll } = params.payload;
      const mutateGamesParams = {
        areAllGamesSelected: selectAll,
        selectedGameIds: gameIds,
        excludedGameIds: filters.excluded,
      };

      commit(mutationTypes.TOGGLE_ADDED_GROUP_GAMES_LOADING, {
        ...mutateGamesParams,
        isLoading: true,
      });
      commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, true);

      return api
        .removeGamesFromGroup(params)
        .then((data) => {
          commit(mutationTypes.SET_TOTAL_GROUP_GAMES, data.totalGamesInGroup);
          commit(mutationTypes.REMOVE_ADDED_GROUP_GAMES, mutateGamesParams);
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, true);
        })
        .catch((err) => {
          handleError({ commit }, err);
          commit(mutationTypes.TOGGLE_GROUP_CHANGES_SAVED, false);
          return {
            isError: true,
          };
        })
        .finally(() => {
          commit(mutationTypes.TOGGLE_ADDED_GROUP_GAMES_LOADING, {
            ...mutateGamesParams,
            isLoading: false,
          });
          commit(mutationTypes.TOGGLE_GROUP_AUTOSAVING, false);
        });
    },
  },

  mutations: {
    [mutationTypes.SET_GROUPS](state, data) {
      state.groups = data;
    },
    [mutationTypes.SET_GROUP](state, data) {
      state.group = data;
    },
    [mutationTypes.SET_CREATED_GROUP](state, data) {
      state.createdGroup = data;
    },
    [mutationTypes.SET_CREATED_TEMP_GROUP_ID](state, tempGroupId) {
      state.createdTempGroupId = tempGroupId;
    },

    [mutationTypes.UPDATE_GROUP](state, data) {
      state.groups = state.groups.map((group) => (group.id === data.id ? data : group));
    },

    [mutationTypes.SET_ALL_GROUP_GAMES_DATA](state, data) {
      state.allGroupTempGamesData = data;
    },
    [mutationTypes.SET_ADDED_GROUP_GAMES_DATA](state, { data, isNextPage, shouldRefetchAllPages }) {
      state.addedGroupGamesData = parseGroupGamesData({
        newData: data,
        prevData: state.addedGroupGamesData,
        isNextPage,
        shouldRefetchAllPages,
      });
    },
    [mutationTypes.SET_ALL_TEMP_GROUP_GAMES_DATA](state, data) {
      state.allTempGroupGamesData = data;
    },
    [mutationTypes.SET_ADDED_TEMP_GROUP_GAMES_DATA](state, { data, isNextPage, shouldRefetchAllPages }) {
      state.addedTempGroupGamesData = parseGroupGamesData({
        newData: data,
        prevData: state.addedTempGroupGamesData,
        isNextPage,
        shouldRefetchAllPages,
      });
    },

    [mutationTypes.SET_TOTAL_GROUP_GAMES](state, totalGamesInGroup) {
      state.totalGamesInGroup = totalGamesInGroup;
    },
    [mutationTypes.SET_TOTAL_TEMP_GROUP_GAMES](state, totalGamesInGroup) {
      state.totalGamesInTempGroup = totalGamesInGroup;
    },

    [mutationTypes.TOGGLE_ALL_GROUP_MEMBERSHIP_TEMP_GAMES](state, params) {
      state.allGroupTempGamesData.games = toggleGamesGroupMembership({
        games: state.allGroupTempGamesData.games,
        ...params,
      });
    },
    [mutationTypes.REMOVE_ADDED_GROUP_GAMES](state, params) {
      state.addedGroupGamesData.games = removeGroupGames({
        games: state.addedGroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_TEMP_GROUP_MEMBERSHIP_GAMES](state, params) {
      state.allTempGroupGamesData.games = toggleGamesGroupMembership({
        games: state.allTempGroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.REMOVE_ADDED_TEMP_GROUP_GAMES](state, params) {
      state.addedTempGroupGamesData.games = removeGroupGames({
        games: state.addedTempGroupGamesData.games,
        ...params,
      });
    },

    [mutationTypes.TOGGLE_ALL_GROUP_TEMP_GAMES_LOADING](state, params) {
      state.allGroupTempGamesData.games = toggleGamesIsLoading({
        games: state.allGroupTempGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_ADDED_GROUP_GAMES_LOADING](state, params) {
      state.addedGroupGamesData.games = toggleGamesIsLoading({
        games: state.addedGroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_ALL_TEMP_GROUP_GAMES_LOADING](state, params) {
      state.allTempGroupGamesData.games = toggleGamesIsLoading({
        games: state.allTempGroupGamesData.games,
        ...params,
      });
    },
    [mutationTypes.TOGGLE_ADDED_TEMP_GROUP_GAMES_LOADING](state, params) {
      state.addedTempGroupGamesData.games = toggleGamesIsLoading({
        games: state.addedTempGroupGamesData.games,
        ...params,
      });
    },

    [mutationTypes.TOGGLE_GROUP_AUTOSAVING](state, bool) {
      state.groupAutosaving = bool;
    },
    [mutationTypes.TOGGLE_GROUP_CHANGES_SAVED](state, bool) {
      state.groupChangesSaved = bool;
    },
  },
};
