import localStorage from '@/utility/localStorage';
import { Audit } from '@nsftx/seven-client-sdk';

const interceptor = (config) => {
  const transformedConfig = config;
  let user = localStorage.getItem('user');
  const isFrasierUrl = config.baseURL.includes(process.env.VUE_APP_FRASIER_API_BASEURL);

  if (!user || isFrasierUrl) return transformedConfig;

  user = JSON.parse(user);

  transformedConfig.headers.Authorization = user.auth?.token;
  transformedConfig.headers['X-Nsft-SCD-Locale'] = user.language;
  transformedConfig.headers.locale = user.language;

  return transformedConfig;
};

const tpaInterceptor = (config) => {
  const transformedConfig = config;
  let user = localStorage.getItem('user');

  if (!user) return transformedConfig;

  user = JSON.parse(user);

  transformedConfig.headers.Authorization = user.auth?.token;

  return transformedConfig;
};

const responseInterceptor = (response) => {
  const transformedConfig = response;

  if (!transformedConfig.config.audit) {
    return transformedConfig;
  }

  transformedConfig.config.status = transformedConfig.statusText || transformedConfig.status;

  Audit.recordInterceptor(transformedConfig.config);

  return transformedConfig;
};

export { interceptor, tpaInterceptor, responseInterceptor };
