import http from './http';

const getSubgroups = ({ companyId, groupId }) => {
  const url = `casino/v2/admin/companies/${companyId}/groups/${groupId}/subgroups`;

  return http.get(url)
    .then((res) => res.data);
};

const editSubgroup = ({ companyId, subgroupId, subgroup }) => {
  const url = `casino/v2/admin/companies/${companyId}/subgroups/${subgroupId}`;

  return http.put(url, subgroup)
    .then((res) => res.data);
};

const createSubgroup = ({ companyId, subgroup }) => {
  const url = `casino/v2/admin/companies/${companyId}/subgroups`;

  return http.post(url, subgroup)
    .then((res) => res.data);
};

const deleteSubgroup = ({ companyId, subgroupId }) => {
  const url = `casino/v2/admin/companies/${companyId}/subgroups/${subgroupId}`;

  return http.delete(url)
    .then((res) => res.data);
};

export default {
  getSubgroups,
  editSubgroup,
  createSubgroup,
  deleteSubgroup,
};
