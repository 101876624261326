import api from '@/api';
import mutationTypes from '@/store/mutationTypes';
import { handleError } from '@/store/actions';
import formatResourceContainerName from '@/utility/resources';

export default {
  state: {
    container: {},
    resources: [],
  },

  getters: {
    resources: (state) => state.resources,
    groupedResources: (state) => {
      const groupedUniqueResources = state.resources.reduce((acc, {
        name, url, extension, title,
      }) => {
        const baseName = name.replace(extension, '');
        const imageKey = ['.avif', '.webp'].includes(extension) ? extension.substring(1) : 'base';

        if (!acc[baseName]) acc[baseName] = { title, formats: {} };
        acc[baseName].formats[imageKey] = url;

        return acc;
      }, {});

      return Object.values(groupedUniqueResources).filter((resource) => resource.formats.base);
    },
    container: (state) => state.container,
  },

  actions: {
    getResources: ({ commit }, companyId) => {
      const params = {
        containerName: formatResourceContainerName(companyId),
      };

      return api
        .getResources(params)
        .then((data) => {
          commit(mutationTypes.SET_RESOURCES, data);
          commit(mutationTypes.SET_CONTAINER, data);
        })
        .catch((err) => handleError({ commit }, err));
    },
  },

  mutations: {
    [mutationTypes.SET_RESOURCES](state, data) {
      state.resources = data.resources;
    },
    [mutationTypes.SET_CONTAINER](state, data) {
      state.container = (data.container && Object.values(data.container)[0]) || {};
    },
  },
};
